// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n4E5qVIRhv3eD8pw3Tqsi h3 {\n    font-family: 'Open Sans', sans-serif;\n  }\n  .n4E5qVIRhv3eD8pw3Tqsi img {\n    width: 100%;\n  }\n  .n4E5qVIRhv3eD8pw3Tqsi a {\n    text-decoration: none;\n    color: black;\n  }\n  .n4E5qVIRhv3eD8pw3Tqsi i {\n    font-size: 1.7em;\n  }\n  .n4E5qVIRhv3eD8pw3Tqsi .v2ML1ALcEkh7ghuVwPI-7 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .n4E5qVIRhv3eD8pw3Tqsi ._4GhkDDDpp8XAMBRrdS4yv {\n    font-family: 'Roboto Mono', monospace;\n    font-size: 0.8em;\n    list-style: none;\n    padding-left: 0px;\n    margin-top: 1rem;\n    display: flex;\n    flex-wrap: wrap; \n  }\n  .n4E5qVIRhv3eD8pw3Tqsi ._4GhkDDDpp8XAMBRrdS4yv li {\n      padding-right: 1rem;\n    }", "",{"version":3,"sources":["webpack://./src/components/portfolio/PortfolioItem.css"],"names":[],"mappings":"AACE;IACE,oCAAoC;EACtC;EACA;IACE,WAAW;EACb;EACA;IACE,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,qCAAqC;IACrC,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,eAAe;EAIjB;EAHE;MACE,mBAAmB;IACrB","sourcesContent":[".PortfolioItem {\n  h3 {\n    font-family: 'Open Sans', sans-serif;\n  }\n  img {\n    width: 100%;\n  }\n  a {\n    text-decoration: none;\n    color: black;\n  }\n  i {\n    font-size: 1.7em;\n  }\n  .top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .tags {\n    font-family: 'Roboto Mono', monospace;\n    font-size: 0.8em;\n    list-style: none;\n    padding-left: 0px;\n    margin-top: 1rem;\n    display: flex;\n    flex-wrap: wrap;\n    li {\n      padding-right: 1rem;\n    } \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PortfolioItem": "n4E5qVIRhv3eD8pw3Tqsi",
	"top": "v2ML1ALcEkh7ghuVwPI-7",
	"tags": "_4GhkDDDpp8XAMBRrdS4yv"
};
export default ___CSS_LOADER_EXPORT___;
