// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2m3e7suhOexSFjgFXUS59N {\n  text-align: center;\n  background-color: whitesmoke;\n  padding: 1.2em 1em 1.2em 1em;\n  font-family: 'Open Sans', sans-serif;\n}\n  ._2m3e7suhOexSFjgFXUS59N ._2ED4pGn2Kvi2lSvfFzIOmb {\n    font-size: 0.8em;\n    color: grey;\n    padding-top: 0.5em;\n  }", "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,4BAA4B;EAC5B,4BAA4B;EAC5B,oCAAoC;AAMtC;EALE;IACE,gBAAgB;IAChB,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".Footer {\n  text-align: center;\n  background-color: whitesmoke;\n  padding: 1.2em 1em 1.2em 1em;\n  font-family: 'Open Sans', sans-serif;\n  .updated {\n    font-size: 0.8em;\n    color: grey;\n    padding-top: 0.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "_2m3e7suhOexSFjgFXUS59N",
	"updated": "_2ED4pGn2Kvi2lSvfFzIOmb"
};
export default ___CSS_LOADER_EXPORT___;
