// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mxkA4p1r6nAQiuyrJeHAB {\n  text-align: center;\n  padding: 2em;\n}\n\n  .mxkA4p1r6nAQiuyrJeHAB code { \n    background-color: #eee; \n    margin-right: 1em;\n    padding: 3px;\n  }\n\n  .mxkA4p1r6nAQiuyrJeHAB pre code {\n    background-color: #eee;\n    border: 1px solid #999;\n    display: block;\n    padding: 20px;\n  }\n\n  .mxkA4p1r6nAQiuyrJeHAB ul {\n    list-style: none;\n    padding: 0px;\n  }\n\n  .mxkA4p1r6nAQiuyrJeHAB ._2wRjsDwmZiUovCsg45vjVC {\n    margin-top: 2.5em;\n    margin-bottom: 2.5em;\n  }\n", "",{"version":3,"sources":["webpack://./src/components/goodies/Goodies.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AAuBd;;EArBE;IACE,sBAAsB;IACtB,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;IACtB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;;EACA;IACE,iBAAiB;IACjB,oBAAoB;EACtB","sourcesContent":[".Goodies {\n  text-align: center;\n  padding: 2em;\n\n  code { \n    background-color: #eee; \n    margin-right: 1em;\n    padding: 3px;\n  }\n\n  pre code {\n    background-color: #eee;\n    border: 1px solid #999;\n    display: block;\n    padding: 20px;\n  }\n\n  ul {\n    list-style: none;\n    padding: 0px;\n  }\n  .partition {\n    margin-top: 2.5em;\n    margin-bottom: 2.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Goodies": "mxkA4p1r6nAQiuyrJeHAB",
	"partition": "_2wRjsDwmZiUovCsg45vjVC"
};
export default ___CSS_LOADER_EXPORT___;
