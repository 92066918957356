import React from 'react';
import DocsContainer from '../containers/DocsContainer';

export default function Docs() {
  return (
    <>
      <DocsContainer />
    </>
  );
}
