// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hs5Y0zzFTtRmyRuhqfsIB {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin: 1rem;\n}\n  .Hs5Y0zzFTtRmyRuhqfsIB i {\n    font-size: 4em;\n    padding: 0.2em;\n    color: whitesmoke;\n  }\n  .Hs5Y0zzFTtRmyRuhqfsIB i:hover {\n    color: rgb(10, 146, 123);\n  }\n  @media (max-width: 500px) {\n    .Hs5Y0zzFTtRmyRuhqfsIB i {\n      font-size: 3em;\n    }\n  }", "",{"version":3,"sources":["webpack://./src/components/intro/Media.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,YAAY;AAcd;EAbE;IACE,cAAc;IACd,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE;MACE,cAAc;IAChB;EACF","sourcesContent":[".Media {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin: 1rem;\n  i {\n    font-size: 4em;\n    padding: 0.2em;\n    color: whitesmoke;\n  }\n  i:hover {\n    color: rgb(10, 146, 123);\n  }\n  @media (max-width: 500px) {\n    i {\n      font-size: 3em;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Media": "Hs5Y0zzFTtRmyRuhqfsIB"
};
export default ___CSS_LOADER_EXPORT___;
