// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2yEuKZ8cn1HGKKi81DZvAB {\n  list-style: none;\n  margin: 2em;\n  padding: 0px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n  ._2yEuKZ8cn1HGKKi81DZvAB ._-4R41be4SWjjpULigQ9PW {\n    border: 2px black solid;\n    border-radius: 10px;\n    width: 450px;\n    padding: 20px;\n    margin: 10px;\n    transition: all .4s ease-in-out;\n    background-color: white;\n  }\n\n  ._2yEuKZ8cn1HGKKi81DZvAB ._-4R41be4SWjjpULigQ9PW:hover {\n    transform: scale(1.05);\n    box-shadow: 0px 5px 30px rgba(9, 10, 16, 0.3);\n  }\n\n  @media (max-width: 500px) {._2yEuKZ8cn1HGKKi81DZvAB {\n    margin: 0px\n}\n  }", "",{"version":3,"sources":["webpack://./src/components/portfolio/PortfolioList.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,uBAAuB;AAoBzB;;EAlBE;IACE,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,+BAA+B;IAC/B,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;IACtB,6CAA6C;EAC/C;;EAEA,2BAvBF;IAwBI;AAEJ;EADE","sourcesContent":[".PortfolioList {\n  list-style: none;\n  margin: 2em;\n  padding: 0px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  .portfolioLi {\n    border: 2px black solid;\n    border-radius: 10px;\n    width: 450px;\n    padding: 20px;\n    margin: 10px;\n    transition: all .4s ease-in-out;\n    background-color: white;\n  }\n\n  .portfolioLi:hover {\n    transform: scale(1.05);\n    box-shadow: 0px 5px 30px rgba(9, 10, 16, 0.3);\n  }\n\n  @media (max-width: 500px) {\n    margin: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PortfolioList": "_2yEuKZ8cn1HGKKi81DZvAB",
	"portfolioLi": "_-4R41be4SWjjpULigQ9PW"
};
export default ___CSS_LOADER_EXPORT___;
