// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Livvic|Roboto+Mono|Open+Sans:700|Gloria+Hallelujah|Montserrat:100&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0px;\n  font-family: 'Livvic', sans-serif;\n  font-size: 18px;\n  display: flex;\n  min-height: 100vh;\n  flex-direction: column;\n}\n\nmain {\n  flex: 1;\n}\n\nh1, h2 {\n  font-family: 'Open Sans', sans-serif;\n  text-align: center;\n}\n\nh2 {\n  font-size: 2em;\n}\n\nh3 {\n  font-family: 'Open Sans', sans-serif;\n}\n\nbutton:hover {\n  cursor: pointer;\n}\n\nsection {\n  padding-top: 3rem;\n  padding-bottom: 3rem;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,iCAAiC;EACjC,eAAe;EACf,aAAa;EACb,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Livvic|Roboto+Mono|Open+Sans:700|Gloria+Hallelujah|Montserrat:100&display=swap');\n\nbody {\n  margin: 0px;\n  font-family: 'Livvic', sans-serif;\n  font-size: 18px;\n  display: flex;\n  min-height: 100vh;\n  flex-direction: column;\n}\n\nmain {\n  flex: 1;\n}\n\nh1, h2 {\n  font-family: 'Open Sans', sans-serif;\n  text-align: center;\n}\n\nh2 {\n  font-size: 2em;\n}\n\nh3 {\n  font-family: 'Open Sans', sans-serif;\n}\n\nbutton:hover {\n  cursor: pointer;\n}\n\nsection {\n  padding-top: 3rem;\n  padding-bottom: 3rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
