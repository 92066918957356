// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2c3lZd2Nr3_yPH_bYM5zgQ {\n  list-style: none;\n  margin: 2em;\n  padding: 0 10% 0 10%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n  ._2c3lZd2Nr3_yPH_bYM5zgQ button {\n    margin: 3px;\n    font-family: 'Roboto Mono', monospace;\n    font-size: 1em;\n    border-radius: 10px;\n    outline: none;\n  }\n\n  ._2c3lZd2Nr3_yPH_bYM5zgQ button:hover {\n    background-image: linear-gradient(-20deg, #11af94 0%, #9be4df 100%);\n  }\n\n  ._2c3lZd2Nr3_yPH_bYM5zgQ ._2W2t4eB8xhzsK46Lz5dC81 {\n    border: black 3px solid;\n    background-image: linear-gradient(-20deg, #11af94 0%, #9be4df 100%);\n  }\n\n  @media (max-width: 500px) {._2c3lZd2Nr3_yPH_bYM5zgQ {\n    padding: 0 0 0 0\n}\n  }", "",{"version":3,"sources":["webpack://./src/components/portfolio/PortfolioTags.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,eAAe;EACf,uBAAuB;AAqBzB;;EAnBE;IACE,WAAW;IACX,qCAAqC;IACrC,cAAc;IACd,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,mEAAmE;EACrE;;EAEA;IACE,uBAAuB;IACvB,mEAAmE;EACrE;;EACA,2BAxBF;IAyBI;AAEJ;EADE","sourcesContent":[".PortfolioTags {\n  list-style: none;\n  margin: 2em;\n  padding: 0 10% 0 10%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  button {\n    margin: 3px;\n    font-family: 'Roboto Mono', monospace;\n    font-size: 1em;\n    border-radius: 10px;\n    outline: none;\n  }\n\n  button:hover {\n    background-image: linear-gradient(-20deg, #11af94 0%, #9be4df 100%);\n  }\n\n  .selected {\n    border: black 3px solid;\n    background-image: linear-gradient(-20deg, #11af94 0%, #9be4df 100%);\n  }\n  @media (max-width: 500px) {\n    padding: 0 0 0 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PortfolioTags": "_2c3lZd2Nr3_yPH_bYM5zgQ",
	"selected": "_2W2t4eB8xhzsK46Lz5dC81"
};
export default ___CSS_LOADER_EXPORT___;
